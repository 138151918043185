<template lang="html" src="./pageOrders.template.vue"></template>

<style lang="scss" src="./pageOrders.scss"></style>

<script>
import { ApiErrorParser, DataTableFilter, CustomFilterForSearchDatatable } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageOrders.i18n');

export default {
  name: 'PageOrders',
  components: { DataTableFilter },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      loading: false,
      headers: [],
      search: '',
      orders: [],
      loadingTable: false,
      statusFilter: [],
      paymentFilter: [],
      typeFilter: [],
      filterObject: {
        status: [
          {
            name: this.$t('NEWORDER'),
            value: 'NEW', 
            img: 'new.png',
            number: 0,
            get selected() {
              return this.number > 0;
            }
          },
          {
            name: this.$t('INPROGRESS'),
            value: 'INPROGRESS', 
            iconColor: 'primary',
            icon: 'fas fa-spinner',
            number: 0,
            get selected() {
              return this.number > 0;
            }
          },
          {
            name: this.$t('DELIVERED'),
            value: 'DELIVERED', 
            iconColor: 'success',
            icon: 'fas fa-check',
            number: 0,
          },
          {
            name: this.$t('CANCELED'),
            value: 'CANCELED', 
            iconColor: 'error',
            icon: 'fas fa-ban',
            number: 0,
          }
        ],
        paymentStatus: [
          {
            name: this.$t('WAITING'),
            value: 'WAITING', 
            img: 'creditCard.svg',
            number: 0,
          },
          {
            name: this.$t('PAID'),
            value: 'PAID', 
            img: 'creditCardCheck.svg',
            number: 0,
          },
          {
            name: this.$t('ERROR'),
            value: 'ERROR', 
            img: 'creditCardTimes.svg',
            number: 0,
          },
        ],
        type: [
          {
            name: 'CMT',
            value: 'public', 
            img: 'logo_cut.png',
            number: 0,
            selected: true
          },
          {
            name: this.$t('PrivateSupplier'),
            value: 'private', 
            iconColor: 'privateSupplier',
            icon: 'fas fa-warehouse',
            number: 0,
          }
        ]
      },
    };
  },
  created() {
    this.getOrders();
  },
  mounted() {
    this.headers = [
      {
        text: this.$t('status'),
        value: 'status',
        filter: (value) => {
          if (this.statusFilter.length === 0) return true;
          if (this.statusFilter.includes(value)) return true;
        }
      },
      {
        text: '',
        sortable: false,
        value: 'paymentStatus',
        filter: (value) => {
          if (this.paymentFilter.length === 0) return true;
          if (this.paymentFilter.includes(value)) {
            return true;
          }
        },
      },
      {
        text: this.$t('Type'),
        value: 'public',
        width: '100px',
        align: 'center',
        sortable: false,
        filter: (value) => {
          if (this.typeFilter.length === 0) return true;
          if ((value && this.typeFilter.includes('public')) || (!value && this.typeFilter.includes('private')) ) return true;
        },
      },
      {
        text: this.$t('invoiceID'),
        value: 'invoiceNumber',
      },
      {
        text: this.$t('purchaseOrder'),
        value: 'quote.purchaseOrderNumber',
        sort: this.sortPurchaseOrderFn
      },
      {
        text: this.$t('price'),
        value: 'quote.selling.totalAmount',
      },
      {
        text: this.$t('Brand'),
        value: 'brand.name',
      },
      {
        text: this.$t('User'),
        value: 'user',
        sort: this.sortUserFn
      },
      { text: this.$t('created'), value: 'created' },
      {
        text: this.$t('Action'),
        value: 'action',
        align: 'center',
        sortable: false,
      },
    ];
  },
  methods: {
    sortPurchaseOrderFn(cf1, cf2) {
      const po1 = cf1?.[0]?.StringValue;
      const po2 = cf2?.[0]?.StringValue;
      if(po1 === po2) {
        return 0;
      }
      return po1 < po2 ? 1 : -1;
    },
    sortUserFn(u1, u2) {
      const fullName1 = u1.lastname + '.' + u1.firstname;
      const fullName2 = u2.lastname + '.' + u2.firstname;
      if(fullName1 === fullName2) {
        return 0;
      }
      return fullName1 < fullName2 ? 1 : -1;
    },
    customFilter(value, search, item) {
      if (!search) return true;
      search = search.toString().toLowerCase();
      if (item.invoiceNumber.toLowerCase().includes(search)) return true;
      if (item.quote.purchaseOrderNumber) {
        if (item.quote.purchaseOrderNumber.toLowerCase().includes(search)) return true;
      }
      if (item.brand.name.toLowerCase().includes(search)) return true;
      return CustomFilterForSearchDatatable.customFilter(value, search, this);
    },
    getOrders() {
      this.loadingTable = true;
      this.loading = true;
      /**
       * GET ORDERS
       */
      this.$apiInstance
        .getAdminOrders()
        .then(
          (data) => {
            this.orders = data;
            /**
             * Reset Filter Object
             */
            for (const key in this.filterObject) {
              for (const obj of this.filterObject[key]) {
                obj.number = 0;
              }
            }
            this.orders.forEach(order => {
              this.filterObject.status.find(x => x.value === order.status).number++;
              const paymentStatusObject = this.filterObject.paymentStatus.find(x => x.value === order.paymentStatus && order.status !== 'CANCELED');
              if(paymentStatusObject) {
                paymentStatusObject.number++;
              }
              this.filterObject.type.find(x => (x.value === 'public' && order.public) || (x.value === 'private' && !order.public)).number++;
            });
          })
        .catch((error) => {
          /**
           * ERROR GET ORDERS
           */
          ApiErrorParser.parse(error);
        })
        .finally(() => {
          this.loadingTable = false;
          this.loading = false;
        });
    },
    updateFilter(updatedFilter) {
      this.statusFilter = updatedFilter.status;
      this.paymentFilter = updatedFilter.paymentStatus;
      this.typeFilter = updatedFilter.type;
      if (this.paymentFilter.length > 0 && !this.statusFilter.includes('NEW') && !this.paymentFilter.includes('PAID')) {
        this.statusFilter.push('NEW');
      }
      
      this.filterObject.status.forEach(status => {
        if(this.paymentFilter.length > 0 && !this.paymentFilter.includes('PAID') && status.value !== 'NEW' ) {
          status.disabled = true;
          this.statusFilter.length = 0;
          this.statusFilter.push('NEW');
        } else {
          status.disabled = false;
        }
      });
    },
    goToPageItem(item) {
      this.$router.push({name: 'Order', params: { orderUUID: `${item._id}`}});
    },
  },
};
</script>
